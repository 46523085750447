@use "@styles/abstracts/mixins";

.projects-container {
	@include mixins.dimensions(100%, 25rem);
	margin: 0 auto;
}

@media only screen and (min-width: 480px) {
	.projects-container {
		@include mixins.dimensions(min(85%, 30rem), 25rem);
	}
}

@media only screen and (min-width: 768px) {
	.projects-container {
		@include mixins.dimensions(100%, 30rem);
	}
}

@media only screen and (min-width: 1024px) {
	.projects-container {
		@include mixins.dimensions(min(100%, 90rem), 35rem);
	}
}
