// Colors
$primary-color: #ffffff;
$secondary-color: #8f9191;
$home-color: #4a00e0;
$valid-color: #70e000;
$error-color: #c81d25;

// Background Colors
$primary-bg-color: #070a1d;
$layout-bg-color: #04050f;

// Linear Gradient
$primary-linear-gradient: linear-gradient(180deg, #8729e0 0%, #4b01e0 100%);

// Border
$primary-border: 3px solid #5005e0;

// Box Shadow
$primary-box-shadow: 0px 4px 4px #00000040;
