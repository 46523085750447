@use "@styles/abstracts/mixins";

.about-container {
	@include mixins.dimensions(85%, 100%);
	display: grid;
	place-items: center;
	margin: 0 auto;
	gap: 2rem;
}

@media only screen and (min-width: 768px) {
	.about-container {
		grid-template-columns: repeat(2, 1fr);
	}
}
