@use "@styles/abstracts/mixins";

.navigation-bar {
	@include mixins.display-flex(row, center, center);

	.sections-list {
		@include mixins.display-flex(row, center, flex-start);
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
}

@media only screen and (min-width: 480px) {
	.navigation-bar {
		.sections-list {
			gap: 1rem;
		}
	}
}

@media only screen and (min-width: 768px) {
	.navigation-bar {
		.sections-list {
			gap: 1rem;
		}
	}
}

@media only screen and (min-width: 1024px) {
	.navigation-bar {
		.sections-list {
			gap: 1.5rem;
		}
	}
}
