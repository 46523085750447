@use "@styles/abstracts/variables";
@use "@styles/abstracts/mixins";

.home-container {
	@include mixins.display-flex(column, center, center);
	@include mixins.dimensions(100%, calc(100dvh - 10rem));

	.home-content {
		text-align: center;

		.introduction {
			@include mixins.font-size-clamp(1.75rem, 4rem);
			color: variables.$home-color;
		}

		.name {
			@include mixins.font-size-clamp(3rem, 6rem);
			text-transform: uppercase;
		}

		.role-title {
			@include mixins.font-size-clamp(1.75rem, 5rem);
			background: variables.$home-color;
			box-shadow: variables.$primary-box-shadow;
			border-radius: 2rem;
			padding: 0 1rem;
		}
	}
}
