@use "@styles/abstracts/variables";
@use "@styles/abstracts/mixins";

.project-slide {
	@include mixins.dimensions(100%, 17rem);
	display: inline-block;
	border: variables.$primary-border;
	border-radius: 1.5rem;
	padding-bottom: 1rem;
	overflow: hidden;
	transform: scale(0.4);
	transition: transform 325ms linear;
	filter: blur(5px);
	cursor: pointer;

	.project-image {
		@include mixins.dimensions(100%, 9rem);
	}

	.project-title {
		@include mixins.font-size-clamp(1.6rem, 2rem);
		padding-left: 1rem;
	}

	.project-description {
		@include mixins.font-size-clamp(1rem, 1.5rem);
	}

	.project-skills {
		@include mixins.display-flex(row, flex-start, center, wrap, 0.5rem);
		padding: 0 1rem;

		.project-skill {
			@include mixins.skill-box();
		}
	}
}

.active-slide {
	@include mixins.dimensions(100%, 100% !important);
	transform: scale(0.9);
	filter: blur(0);

	&:hover {
		transform: scale(0.95);
	}
}

@media only screen and (min-width: 768px) {
	.project-slide {
		.project-image {
			@include mixins.dimensions(100%, 12rem);
		}

		.project-skills {
			.project-skill {
				padding-bottom: 0.7rem;
			}
		}
	}
}

@media only screen and (min-width: 1024px) {
	.project-slide {
		@include mixins.dimensions(100%, 20rem);

		.project-image {
			@include mixins.dimensions(100%, 15rem);
		}

		.project-skills {
			.project-skill {
				padding-bottom: 0.8rem;
			}
		}
	}
}
