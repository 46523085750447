@use "@styles/abstracts/variables";
@use "@styles/abstracts/mixins";

.header-container {
	@include mixins.dimensions(max(100%, 320px), fit-content);
	position: fixed;
	background-color: variables.$layout-bg-color;
	box-shadow: variables.$primary-box-shadow;
	z-index: 1;

	.header-content {
		@include mixins.display-flex(row, flex-end, center);
		position: relative;
		padding: 0.5rem 1rem;
	}
}

@media only screen and (min-width: 480px) {
	.header-container {
		.header-content {
			position: relative;
			padding: 0.5rem 3rem;
		}
	}
}

@media only screen and (min-width: 768px) {
	.header-container {
		.header-content {
			justify-content: center;
		}
	}
}
