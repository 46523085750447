@use "@styles/abstracts/variables";
@use "@styles/abstracts/mixins";

.social-link {
	@include mixins.dimensions(3rem, 3rem);
	-webkit-tap-highlight-color: #{variables.$primary-bg-color}88 !important;

	@media (hover: hover) {
		opacity: 1;

		&:hover {
			opacity: 0.5;
		}
	}
}
