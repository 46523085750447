@use "@styles/abstracts/variables";
@use "@styles/abstracts/mixins";

.footer-container {
	@include mixins.display-flex(column, flex-start, center, $gap: 1rem);
	@include mixins.dimensions(100%, 100%);
	background-color: variables.$layout-bg-color;
	padding: 2rem 0 1rem;

	.socials-container {
		@include mixins.display-flex(row, center, center, $gap: 2rem);
	}

	.copyright {
		font-size: 1rem;
		color: variables.$secondary-color;
	}
}
