@use "@styles/abstracts/mixins";

.icon {
	@include mixins.dimensions(16px, 16px);
	position: absolute;
	left: 1rem;
}

@media only screen and (min-width: 480px) {
	.icon {
		@include mixins.dimensions(24px, 24px);
		left: 2rem;
	}
}

@media only screen and (min-width: 768px) {
	.icon {
		@include mixins.dimensions(32px, 32px);
	}
}
