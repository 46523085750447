@use "@styles/abstracts/variables";
@use "@styles/abstracts/mixins";

.contact-container {
	@include mixins.display-flex(column, center, center, $gap: 1.5rem);
	@include mixins.dimensions(min(100%, 60rem), 100%);
	margin: 0 auto;

	.field-container {
		@include mixins.dimensions(90%, 100%);
	}

	.submit {
		@include mixins.dimensions(90%, 100%);
		@include mixins.font-size-clamp(1rem, 1.6rem);
		color: variables.$primary-color;
		background: variables.$primary-linear-gradient;
		border: variables.$primary-border;
		border-radius: 0.5rem;
		margin: 0 auto;
		padding: 1rem 0;
		font-weight: 600;
		letter-spacing: 2px;
		text-transform: uppercase;
		-webkit-tap-highlight-color: #{variables.$primary-bg-color}88 !important;
	}
}

@media only screen and (min-width: 768px) {
	.contact-container {
		.submit {
			@include mixins.dimensions(50%, 100%);
		}
	}
}
