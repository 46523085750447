@use "@styles/abstracts/mixins";

.skills-container {
	@include mixins.dimensions(100%, 100%);
	background: linear-gradient(180deg, #d9d9d9 -25%, #ffffff00 10%);
	border-radius: 50%;
	padding: 0 1.5rem;
	text-align: center;

	.title {
		@include mixins.font-size-clamp(1.6rem, 2rem);
	}

	.skills-content {
		@include mixins.display-flex(row, center, center, wrap, 0.5rem);
		@include mixins.dimensions(min(100%, 60rem), fit-content);
		margin: 0 auto;

		.skill {
			@include mixins.skill-box();
		}
	}
}

@media only screen and (min-width: 768px) {
	.skills-container {
		grid-column: 1 / -1;

		.skills-content {
			gap: 1rem;

			.skill {
				padding-bottom: 0.7rem;
			}
		}
	}
}

@media only screen and (min-width: 1024px) {
	.skills-container {
		.skills-content {
			.skill {
				padding-bottom: 0.8rem;
			}
		}
	}
}
