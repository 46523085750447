@use "@styles/abstracts/variables";
@use "@styles/abstracts/mixins";

.section-list-item {
	display: inherit;

	.section-link {
		@include mixins.font-size-clamp(0.6rem, 1rem);
		font-weight: 700;
		letter-spacing: 2px;
		text-transform: uppercase;
		padding: 1rem 0.5rem;
		-webkit-tap-highlight-color: #{variables.$layout-bg-color}88 !important;

		@media (hover: hover) {
			position: relative;

			&::after {
				@include mixins.dimensions(100%, 2px);
				content: "";
				position: absolute;
				bottom: 0.5rem;
				left: 0;
				border-bottom: 2px solid variables.$primary-color;
				transform: scaleX(0);
				transition: transform 325ms ease;
			}

			&:hover::after {
				transform: scaleX(0.95);
			}
		}
	}
}

@media only screen and (min-width: 480px) {
	.section-list-item {
		.section-link {
			padding: 1rem 0.8rem;

			@media (hover: hover) {
				&:hover::after {
					transform: scaleX(0.8);
				}
			}
		}
	}
}

@media only screen and (min-width: 768px) {
	.section-list-item {
		.section-link {
			padding: 1rem 1.5rem;

			@media (hover: hover) {
				&:hover::after {
					transform: scaleX(0.75);
				}
			}
		}
	}
}

@media only screen and (min-width: 1024px) {
	.section-list-item {
		.section-link {
			padding: 1rem 2rem;

			@media (hover: hover) {
				&:hover::after {
					transform: scaleX(0.7);
				}
			}
		}
	}
}
