@use "@styles/abstracts/variables";
@use "@styles/abstracts/mixins";

.profile-container {
	background: variables.$primary-linear-gradient;
	border-radius: 50%;
	padding: 0.25rem 0.25rem 0;

	.profile-picture {
		@include mixins.dimensions(128px, 128px);
		background: variables.$primary-bg-color;
		border-radius: 50%;
		padding: 0.25rem;
	}
}

@media only screen and (min-width: 768px) {
	.profile-container {
		padding: 0.5rem 0.5rem 0.25rem;

		.profile-picture {
			@include mixins.dimensions(256px, 256px);
			padding: 0.5rem;
		}
	}
}
