@use "@styles/abstracts/variables";
@use "@styles/abstracts/functions";

@mixin display-flex($direction, $justift-content, $align-items, $wrap: nowrap, $gap: 0rem) {
	display: flex;
	flex-direction: $direction;
	justify-content: $justift-content;
	align-items: $align-items;
	flex-wrap: $wrap;
	gap: $gap;
}

@mixin dimensions($width, $height) {
	width: $width;
	height: $height;
}

@mixin font-size-clamp($minimum, $maximum) {
	font-size: clamp($minimum, functions.calc-clamp-preferred-value($minimum, $maximum), $maximum);
}

@mixin skill-box() {
	@include font-size-clamp(1rem, 1.6rem);
	background: variables.$primary-linear-gradient;
	box-shadow: variables.$primary-box-shadow;
	border-radius: 0.5rem;
	padding: 0.5rem 1rem;
	font-weight: 600;
}
