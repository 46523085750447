@use "@styles/abstracts/variables";
@use "@styles/abstracts/mixins";

.section-container {
	@include mixins.display-flex(column, center, center);
	@include mixins.dimensions(100%, 100%);
	background-color: variables.$primary-bg-color;
	padding: 3rem 0;

	.section-content {
		@include mixins.dimensions(min(100%, 100rem), 100%);

		.section-header {
			text-align: center;

			.title {
				@include mixins.font-size-clamp(2rem, 3.5rem);
				text-transform: uppercase;
				text-decoration: underline;
				text-underline-position: under;
			}
		}
	}
}
