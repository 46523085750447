@use "@styles/abstracts/variables";
@use "@styles/abstracts/mixins";

.field-border {
	border: variables.$primary-border;
	border-radius: 1rem;
	transition: border-color 100ms linear;

	.field-label {
		@include mixins.font-size-clamp(1rem, 1.6rem);
		margin-left: 1rem;
		padding: 0 0.5rem;
		font-weight: 600;
		letter-spacing: 1px;
	}

	.field-input {
		@include mixins.dimensions(100%, fit-content);
		@include mixins.font-size-clamp(0.8rem, 1.2rem);
		color: variables.$primary-color;
		background-color: variables.$primary-bg-color;
		padding: 0.5rem;
		border: none;
		outline: none;
		line-height: 2rem;
	}

	&[data-field-state="-1"] {
		border-color: variables.$error-color;
		animation: shake 325ms 2;
	}

	&[data-field-state="1"] {
		border-color: variables.$valid-color;
	}
}

.field-error {
	@include mixins.font-size-clamp(0.8rem, 1.2rem);
	min-height: 2rem;
	color: variables.$error-color;
	padding: 1rem 0.5rem 0;
	font-weight: 600;
	opacity: 0;

	&[data-show="true"] {
		opacity: 1;
		transition: opacity 325ms linear;
	}
}

@keyframes shake {
	25% {
		translate: 5px 0;
	}
	50% {
		translate: -5px 0;
	}
	75% {
		translate: 5px 0;
	}
}
